@charset "UTF-8";
@import "../reset";
@import "../common";

.common-body {
    padding-top: 30px;
    height: 100vh;

}

.common-body-main {
    text-align: center;
    padding-bottom: 52px;

    .common-img-box {
        width: 80%;
        max-width: 365px;
        margin: 0 auto 75px;

        @include sp {
            margin: 0 auto 35px;
        }

        img {
            width: 100%;
        }
    }
    
    .common-title {
        @include MontB;
        font-size: 7.1rem;
        letter-spacing: .03em;
        color: $navy;
        margin-bottom: 75px;

        @include sp {
            font-size: 4.1rem;
            margin-bottom: 35px;
        }
    }

    .common-txt {
        @include NotoM;
        font-size: 1.4rem;
        letter-spacing: .06em;
        line-height: 1.8;
        color: $deep_gray;
        padding: 0 15px;
    }
}

.btn-box {

    &-inner {

        .btn-more {
    
            &::after {
                background-image: url(../../../assets/img/common/arr.png);
            }
        }
    }

}