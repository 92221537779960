@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
@import url("https://fonts.googleapis.com/css?family=Oswald:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

* {
  box-sizing: border-box; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box; }
  input[type="submit"]::-webkit-search-decoration,
  input[type="button"]::-webkit-search-decoration {
    display: none; }
  input[type="submit"]:focus,
  input[type="button"]:focus {
    outline-offset: -2px; }

html {
  font-size: 62.5%; }

body.no-scroll {
  overflow: hidden; }

.top-header {
  width: 100%;
  position: relative;
  z-index: 200; }
  .top-header.normal {
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (max-width: 960px) {
      .top-header.normal {
        position: fixed;
        top: 0;
        left: 0; } }
  .top-header.page {
    position: fixed;
    top: 0;
    left: 0; }
    @media screen and (max-width: 960px) {
      .top-header.page {
        display: none; } }
    .top-header.page.remain-tab {
      display: block; }
  .top-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7); }
  .top-header__inner {
    width: 90%;
    max-width: 1080px;
    max-width: 1080px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0; }
    @media screen and (max-width: 1180px) {
      .top-header__inner {
        width: 90%; } }
    @media screen and (max-width: 768px) {
      .top-header__inner {
        padding: calc(40vw / 7.5) 0; } }
    .top-header__inner .header-logo {
      height: 37px;
      cursor: pointer; }
      .top-header__inner .header-logo:hover {
        opacity: .5; }
        @media screen and (max-width: 960px) {
          .top-header__inner .header-logo:hover {
            opacity: 1; } }
      @media screen and (max-width: 600px) {
        .top-header__inner .header-logo {
          height: 28px; } }
      .top-header__inner .header-logo img {
        display: block;
        height: 100%;
        width: auto; }
    @media screen and (max-width: 960px) {
      .top-header__inner .top-nav {
        display: none; } }
    .top-header__inner .top-nav__inner {
      display: flex;
      align-items: center;
      height: 100%; }
      .top-header__inner .top-nav__inner .nav-item {
        padding: 0 10px;
        cursor: pointer; }
        .top-header__inner .top-nav__inner .nav-item:hover {
          opacity: .5; }
          @media screen and (max-width: 960px) {
            .top-header__inner .top-nav__inner .nav-item:hover {
              opacity: 1; } }
        .top-header__inner .top-nav__inner .nav-item .nav-link {
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          font-size: 12px;
          letter-spacing: .12em;
          color: #1e2b69;
          cursor: pointer; }
      .top-header__inner .top-nav__inner .btn-contact {
        width: 140px;
        height: 45px;
        border-radius: 22.5px;
        background-color: #1e2b69;
        margin-left: 18px;
        cursor: pointer; }
        .top-header__inner .top-nav__inner .btn-contact.none {
          display: none; }
        .top-header__inner .top-nav__inner .btn-contact:hover {
          opacity: .5; }
          @media screen and (max-width: 960px) {
            .top-header__inner .top-nav__inner .btn-contact:hover {
              opacity: 1; } }
        .top-header__inner .top-nav__inner .btn-contact .contact-link {
          width: 100%;
          height: 100%;
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          color: #fff;
          font-size: 12px;
          letter-spacing: .06em;
          display: flex;
          justify-content: center;
          align-items: center; }
    .top-header__inner .menu-btn {
      display: none;
      cursor: pointer; }
      .top-header__inner .menu-btn:hover {
        opacity: .5; }
        @media screen and (max-width: 960px) {
          .top-header__inner .menu-btn:hover {
            opacity: 1; } }
      @media screen and (max-width: 960px) {
        .top-header__inner .menu-btn {
          display: flex; } }

main.page-main {
  margin-top: 125px;
  position: relative;
  z-index: 100;
  margin-bottom: 316px; }
  @media screen and (max-width: 768px) {
    main.page-main {
      margin-top: calc( 40px + calc(94vw / 7.5)); } }
  @media screen and (max-width: 600px) {
    main.page-main {
      margin-bottom: calc(598vw / 7.5); } }
  main.page-main .page-body {
    background-color: #fff;
    overflow: hidden; }
    main.page-main .page-body.no-overflow {
      overflow: initial; }

.page-head {
  padding-top: 30px; }
  @media screen and (max-width: 768px) {
    .page-head {
      padding-top: calc(35vw / 7.5); } }
  @media screen and (max-width: 600px) {
    .page-head {
      padding-top: 0; } }
  .page-head__inner {
    width: 90%;
    max-width: 1080px;
    margin: auto; }
    @media screen and (max-width: 1180px) {
      .page-head__inner {
        width: 90%; } }
    .page-head__inner .page-title {
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 700;
      color: #1d2974;
      font-size: 2.4rem;
      letter-spacing: .14em;
      line-height: 1.6; }
      @media screen and (max-width: 768px) {
        .page-head__inner .page-title {
          font-size: calc(38vw / 7.5); } }
      .page-head__inner .page-title span {
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: .08em; }

.main-nav-container {
  position: relative;
  height: calc(80px + 50 * ((100vw - 320px)/ 1440)); }
  @media screen and (max-width: 600px) {
    .main-nav-container {
      height: calc(83px + 50 * ((100vw - 320px)/ 1440)); } }
  @media screen and (max-width: 960px) {
    .main-nav-container.close-tab {
      display: none; } }
  .main-nav-container .main-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: calc(80px + 50 * ((100vw - 320px)/ 1440));
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); }
    @media screen and (max-width: 600px) {
      .main-nav-container .main-nav {
        height: calc(83px + 50 * ((100vw - 320px)/ 1440)); } }
    .main-nav-container .main-nav.nav-fixed {
      position: fixed;
      top: 0;
      left: 0; }
    .main-nav-container .main-nav__inner {
      width: 90%;
      max-width: 1080px;
      max-width: 1080px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 960px) {
        .main-nav-container .main-nav__inner {
          width: 90%; } }
      .main-nav-container .main-nav__inner.recruit {
        position: relative; }
        .main-nav-container .main-nav__inner.recruit .btn-entry {
          position: absolute;
          top: 160%;
          right: 0;
          transform: translateX(28.5%);
          display: block;
          width: 120px;
          height: 120px;
          background-color: #1e2b69;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 2.2rem;
          color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media screen and (max-width: 768px) {
            .main-nav-container .main-nav__inner.recruit .btn-entry {
              width: calc(120vw / 7.5);
              min-width: 80px;
              height: calc(120vw / 7.5);
              min-height: 80px;
              transform: translateX(calc( calc( 120vw / 7.5 ) / 4.3)); } }
          @media screen and (max-width: 600px) {
            .main-nav-container .main-nav__inner.recruit .btn-entry {
              font-size: 1.7rem; } }
          .main-nav-container .main-nav__inner.recruit .btn-entry:hover {
            background-color: #65c2d5; }
      .main-nav-container .main-nav__inner .main-nav-logo {
        height: 37px;
        cursor: pointer; }
        .main-nav-container .main-nav__inner .main-nav-logo:hover {
          opacity: .5; }
          @media screen and (max-width: 960px) {
            .main-nav-container .main-nav__inner .main-nav-logo:hover {
              opacity: 1; } }
        @media screen and (max-width: 600px) {
          .main-nav-container .main-nav__inner .main-nav-logo {
            height: 28px; } }
        .main-nav-container .main-nav__inner .main-nav-logo img {
          height: 100%;
          width: auto; }

.top-nav__inner {
  position: relative; }
  .top-nav__inner .btn-entry {
    right: 0;
    transform: translateX(28.5%);
    display: block;
    width: 120px;
    height: 120px;
    background-color: #1e2b69;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .top-nav__inner .btn-entry {
        width: calc(120vw / 7.5);
        min-width: 80px;
        height: calc(120vw / 7.5);
        min-height: 80px;
        transform: translateX(calc( calc( 120vw / 7.5 ) / 4.3)); } }
    @media screen and (max-width: 600px) {
      .top-nav__inner .btn-entry {
        font-size: 1.7rem; } }
    .top-nav__inner .btn-entry:hover {
      background-color: #65c2d5; }

@keyframes fadeUpMenu {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOutMenu {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes showMainMenu {
  0% {
    height: 0; }
  99% {
    height: calc(1559vw / 6); }
  100% {
    height: auto; } }

@keyframes hideMainMenu {
  0% {
    height: auto; }
  1% {
    height: calc(1559vw / 6); }
  100% {
    height: 0; } }

.m-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  display: none; }
  @media screen and (max-width: 600px) {
    .m-menu {
      overflow-y: scroll; } }
  .m-menu__inner .m-menu-top {
    height: calc(80px + 50 * ((100vw - 320px)/ 1440)); }
    @media screen and (max-width: 600px) {
      .m-menu__inner .m-menu-top {
        height: calc(83px + 50 * ((100vw - 320px)/ 1440)); } }
    .m-menu__inner .m-menu-top .m-menu-header {
      width: 100%;
      background-color: #024272;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .m-menu__inner .m-menu-top .m-menu-header__inner {
        width: 90%;
        max-width: 1080px;
        max-width: 1080px;
        height: 0;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        @media screen and (max-width: 768px) {
          .m-menu__inner .m-menu-top .m-menu-header__inner {
            width: 90%; } }
        .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo {
          height: 37px;
          opacity: 0; }
          @media screen and (max-width: 600px) {
            .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo {
              height: 28px;
              margin-left: 5vw; } }
          .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo svg {
            height: 100%; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo svg {
                transform: translateX(-13px); } }
            .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo svg polygon,
            .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo svg path,
            .m-menu__inner .m-menu-top .m-menu-header__inner .menu-header-logo svg rect {
              fill: #fff; }
        .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn {
          background-color: #0f5b92;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
          opacity: 0; }
          @media screen and (max-width: 600px) {
            .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn {
              width: 45px;
              height: 45px; } }
          .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::before, .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::after {
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 17px;
            height: 2px;
            border-radius: 1px;
            margin: -2.5% 0 0 -15%;
            background: #FFF; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::before, .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::after {
                margin: -0% 0 0 -18.3%; } }
          .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::before {
            transform: rotate(-45deg); }
          .m-menu__inner .m-menu-top .m-menu-header__inner .close-btn span::after {
            transform: rotate(45deg); }
  .m-menu__inner .m-menu-main {
    display: flex; }
    @media screen and (max-width: 600px) {
      .m-menu__inner .m-menu-main {
        flex-direction: column;
        height: 0;
        transition: all .7s; }
        .m-menu__inner .m-menu-main.show {
          animation: showMainMenu .7s ease-in-out forwards; }
        .m-menu__inner .m-menu-main.hide {
          animation: hideMainMenu .7s ease-in-out forwards; } }
    .m-menu__inner .m-menu-main .menu-ml {
      width: 57.6388vw;
      height: calc(100vh - calc(80px + 50 * ((100vw - 320px)/ 1440)));
      overflow-y: scroll;
      position: relative; }
      @media screen and (max-width: 600px) {
        .m-menu__inner .m-menu-main .menu-ml {
          width: 100vw;
          height: auto;
          overflow-y: auto; } }
      .m-menu__inner .m-menu-main .menu-ml__inner {
        width: 0;
        min-height: 100%;
        background-color: #0f5b92; }
        @media screen and (max-width: 600px) {
          .m-menu__inner .m-menu-main .menu-ml__inner {
            width: 100%;
            min-height: auto; } }
        .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content {
          padding-top: calc(50px + 30 * ((100vw - 320px)/ 1440));
          padding-bottom: calc(50px + 30 * ((100vw - 320px)/ 1440));
          padding-left: 12.6388vw;
          padding-right: 4.5833vw;
          opacity: 0; }
          @media screen and (max-width: 960px) {
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content {
              padding-left: 4.5833vw; } }
          @media screen and (max-width: 600px) {
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content {
              padding-top: calc(70vw / 7.5);
              padding-bottom: calc(70vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content.show {
            animation: fadeUpMenu 1.5s ease-in-out forwards; }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content.out {
            animation: fadeOutMenu .4s ease-in-out forwards; }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .ml-menu-title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1.4rem;
            letter-spacing: .1em;
            color: #fff;
            margin-bottom: 50px; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .ml-menu-title {
                margin-bottom: calc(70vw / 7.5);
                font-size: calc(20vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists {
            display: flex; }
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list {
              width: 50%; }
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item {
                margin-bottom: 40px; }
                .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item:nth-last-child(1) {
                  margin-bottom: 0px; }
                @media screen and (max-width: 600px) {
                  .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item {
                    margin-bottom: calc(50vw / 7.5); } }
                .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  font-size: 2.2rem;
                  letter-spacing: .05em;
                  color: #fff; }
                  @media screen and (max-width: 1180px) {
                    .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link {
                      font-size: 2rem; } }
                  @media screen and (max-width: 768px) {
                    .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link {
                      font-size: calc(18vw / 7.5); } }
                  @media screen and (max-width: 600px) {
                    .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link {
                      font-size: calc(34vw / 7.5); } }
                  .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link span {
                    font-family: 'Noto Sans JP', sans-serif;
                    font-weight: 700;
                    font-size: 1.2rem;
                    color: #d3d3d3;
                    margin-left: 8px; }
                    @media screen and (max-width: 768px) {
                      .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link span {
                        font-size: 1rem; } }
                    @media screen and (max-width: 600px) {
                      .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-menu .menu-lists .menu-list .menu-item .menu-link span {
                        font-size: calc(20vw / 7.5);
                        margin-left: calc(8vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec {
            margin-top: 60px;
            width: 100%;
            padding-top: 30.7692%;
            border-radius: 15px;
            position: relative; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec {
                margin-top: calc(83vw / 7.5); } }
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec .bg-rec {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 15px; }
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec-content {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10%; }
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec-content .menu-ml-rec-txt {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 3rem;
                letter-spacing: .1em;
                color: #1e2b69; }
                @media screen and (max-width: 600px) {
                  .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec-content .menu-ml-rec-txt {
                    font-size: calc(35vw / 7.5); } }
                .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec-content .menu-ml-rec-txt span {
                  display: block;
                  font-family: 'Noto Sans JP', sans-serif;
                  font-weight: 500;
                  font-size: 1.4rem;
                  margin-bottom: 10px; }
                  @media screen and (max-width: 600px) {
                    .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-rec-content .menu-ml-rec-txt span {
                      font-size: calc(20vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-fb {
            color: #fff;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1rem;
            letter-spacing: .1em;
            display: flex;
            align-items: center;
            margin-top: 40px;
            line-height: 2rem; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-fb {
                margin-top: calc(55vw / 7.5); } }
            .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-fb .fab.fa-facebook {
              font-size: 1.5rem;
              vertical-align: middle;
              margin-right: 15px; }
              .m-menu__inner .m-menu-main .menu-ml__inner .menu-ml-content .menu-ml-fb .fab.fa-facebook::before {
                border-radius: 50%; }
    .m-menu__inner .m-menu-main .menu-srv {
      width: calc(100vw - 57.6388vw);
      height: calc(100vh - calc(80px + 50 * ((100vw - 320px)/ 1440)));
      overflow-y: scroll;
      position: relative; }
      @media screen and (max-width: 600px) {
        .m-menu__inner .m-menu-main .menu-srv {
          width: 100vw;
          height: auto;
          overflow-y: auto; } }
      .m-menu__inner .m-menu-main .menu-srv__inner {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        min-height: 100%;
        background-color: #f7f7f7; }
        @media screen and (max-width: 600px) {
          .m-menu__inner .m-menu-main .menu-srv__inner {
            position: static;
            width: 100%; } }
        .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content {
          padding-top: calc(50px + 30 * ((100vw - 320px)/ 1440));
          padding-bottom: calc(50px + 30 * ((100vw - 320px)/ 1440));
          padding-right: calc(183vw / 14.4);
          padding-left: calc(72vw / 14.4);
          opacity: 0; }
          @media screen and (max-width: 960px) {
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content {
              padding-right: calc(72vw / 14.4); } }
          @media screen and (max-width: 600px) {
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content {
              padding-top: calc(70vw / 7.5);
              padding-bottom: calc(100vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content.show {
            animation: fadeUpMenu 1.5s ease-in-out forwards; }
          .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content.out {
            animation: fadeOutMenu .4s ease-in-out forwards; }
          .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1.4rem;
            letter-spacing: .1em;
            color: #0f5b92;
            margin-bottom: 50px; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-title {
                font-size: calc(20vw / 7.5);
                margin-bottom: calc(70vw / 7.5); } }
          .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box {
            display: block;
            width: 100%;
            padding-top: 33.8028%;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0px -1px 4px 4px #efeeee;
            margin-bottom: 19px;
            position: relative;
            cursor: pointer;
            transition: all .3s; }
            @media screen and (max-width: 600px) {
              .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box {
                margin-bottom: calc(19vw / 3.7); } }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box::after {
              transition: all .3s; }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box::before, .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box::after {
              border-radius: 15px;
              background: #1578bf;
              content: '';
              position: absolute;
              z-index: 0; }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box::before {
              height: 100%;
              left: 0;
              top: 0;
              width: 100%; }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box::after {
              background: #fff;
              height: 100%;
              left: 0;
              top: 0;
              width: 100%; }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box:hover:after {
              height: 0;
              left: 50%;
              top: 50%;
              width: 0; }
            .m-menu__inner .m-menu-main .menu-srv__inner .menu-srv-content .menu-srv-box .srv-box .srv-logo {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
              width: 63%; }

.section-team {
  background-color: #f8f8f8;
  overflow: hidden; }
  .section-team.page-about {
    background-color: #fff; }
  .section-team-main {
    width: 90%;
    max-width: 1080px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media screen and (max-width: 600px) {
      .section-team-main {
        display: block;
        width: 100%; } }
    .section-team-main .swiper-wrapper {
      display: flex;
      justify-content: flex-start; }
    .section-team-main .member-box {
      width: 100%;
      padding: 0 5%;
      display: flex;
      justify-content: space-between; }
    .section-team-main .team-swiper-pagination {
      text-align: center; }
      .section-team-main .team-swiper-pagination .swiper-pagination-bullet {
        width: 10px;
        height: 10px; }
      .section-team-main .team-swiper-pagination .swiper-pagination-bullet-active {
        background: #22a7c3; }
    .section-team-main .member-card {
      width: 23.1481%;
      margin-bottom: 30px; }
      @media screen and (max-width: 1180px) {
        .section-team-main .member-card {
          width: 47%;
          margin-bottom: 0; }
          .section-team-main .member-card:nth-of-type(-n+2) {
            margin-bottom: 30px; } }
      .section-team-main .member-card .member-img {
        width: 100%;
        padding-top: 72%;
        position: relative; }
        .section-team-main .member-card .member-img img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .section-team-main .member-card .member-info {
        padding: 18px;
        background-color: #fff;
        width: 82.35%;
        margin: auto;
        position: relative;
        margin-top: -7%; }
        @media screen and (max-width: 600px) {
          .section-team-main .member-card .member-info {
            padding: calc(24vw / 7.5); } }
        .section-team-main .member-card .member-info .member-branch,
        .section-team-main .member-card .member-info .member-sort {
          font-family: 'Noto Sans JP', sans-serif;
          font-weight: 500;
          font-size: 1.1rem;
          letter-spacing: .02em;
          line-height: 1.6;
          color: #22a7c3; }
          @media screen and (max-width: 1180px) {
            .section-team-main .member-card .member-info .member-branch,
            .section-team-main .member-card .member-info .member-sort {
              font-size: calc(13vw / 7.5);
              line-height: 1.3; } }
          @media screen and (max-width: 600px) {
            .section-team-main .member-card .member-info .member-branch,
            .section-team-main .member-card .member-info .member-sort {
              font-size: calc(18vw / 7.5); } }
        @media screen and (max-width: 1180px) {
          .section-team-main .member-card .member-info .member-branch {
            margin-bottom: 5px; } }
        @media screen and (max-width: 1180px) {
          .section-team-main .member-card .member-info .member-sort {
            margin-bottom: 7px; } }
        .section-team-main .member-card .member-info .supple {
          font-family: 'Noto Sans JP', sans-serif;
          font-weight: 500;
          font-size: 1.1rem;
          letter-spacing: .02em;
          line-height: 1.6;
          color: #6c6c6c; }
          @media screen and (max-width: 1180px) {
            .section-team-main .member-card .member-info .supple {
              font-size: calc(12vw / 7.5); } }
          @media screen and (max-width: 600px) {
            .section-team-main .member-card .member-info .supple {
              font-size: calc(15vw / 7.5); } }
        .section-team-main .member-card .member-info .member-name {
          margin-top: 18px;
          font-family: 'Noto Sans JP', sans-serif;
          font-weight: 500;
          font-size: 1.8rem;
          color: #000;
          letter-spacing: .1em;
          line-height: 1.22; }
          @media screen and (max-width: 1180px) {
            .section-team-main .member-card .member-info .member-name {
              font-size: calc(14vw / 7.5); } }
          @media screen and (max-width: 600px) {
            .section-team-main .member-card .member-info .member-name {
              font-size: calc(24vw / 7.5);
              margin-top: calc(25vw / 7.5); } }
          .section-team-main .member-card .member-info .member-name span {
            margin-top: 6px;
            display: block;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 1rem;
            letter-spacing: .03em;
            color: #6c6c6c; }
            @media screen and (max-width: 600px) {
              .section-team-main .member-card .member-info .member-name span {
                font-size: calc(15vw / 7.5); } }
  .section-team .btn-area {
    padding: 40px 0 50px 0; }

.related-case {
  padding-bottom: 50px; }
  @media screen and (max-width: 768px) {
    .related-case {
      padding-bottom: calc(50vw/ 7.5); } }

.case-blog-list__inner {
  width: 90%;
  max-width: 1080px;
  margin: auto; }
  .case-blog-list__inner .case-blog-container {
    width: 100%;
    margin-bottom: 50px;
    padding-top: 50px; }
    .case-blog-list__inner .case-blog-container:nth-of-type(1) {
      padding-top: 0px; }
    @media screen and (max-width: 600px) {
      .case-blog-list__inner .case-blog-container:nth-last-child(1) {
        margin-bottom: 15px; } }
    .case-blog-list__inner .case-blog-container .case-thum-box {
      display: block;
      width: 100%;
      padding-top: 39%;
      position: relative; }
      @media screen and (max-width: 600px) {
        .case-blog-list__inner .case-blog-container .case-thum-box {
          padding-top: 43%; } }
      .case-blog-list__inner .case-blog-container .case-thum-box .case-thum {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .case-blog-list__inner .case-blog-container .case-thum-box .case-logo {
        position: absolute;
        bottom: 15px;
        left: 15px;
        width: 127px;
        height: 127px;
        object-fit: contain;
        background-color: #fff; }
        @media screen and (max-width: 768px) {
          .case-blog-list__inner .case-blog-container .case-thum-box .case-logo {
            width: 90px;
            height: 90px; } }
        @media screen and (max-width: 600px) {
          .case-blog-list__inner .case-blog-container .case-thum-box .case-logo {
            width: 70px;
            height: 70px; } }
  .case-blog-list__inner .case-info-container {
    margin-top: 20px; }
    .case-blog-list__inner .case-info-container .case-info-inner .case-info-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px; }
      @media screen and (max-width: 600px) {
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top {
          margin-bottom: 13px; } }
      @media screen and (max-width: 768px) {
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%; } }
      @media screen and (max-width: 600px) {
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var {
          display: block; } }
      .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 12px; }
        @media screen and (max-width: 768px) {
          .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types {
            margin-bottom: 0; } }
        @media screen and (max-width: 600px) {
          .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types {
            margin-bottom: 13px; } }
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .v_input {
          display: none;
          padding: 0; }
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-parent,
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-child {
          display: block;
          font-family: 'Noto Sans JP', sans-serif;
          font-weight: 700;
          font-size: 1.2rem;
          letter-spacing: .06em;
          color: #22a7c3;
          padding: 5px 15px;
          border-radius: 12px;
          border: 1px solid #22a7c3;
          margin-right: 7px;
          cursor: pointer; }
          .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-parent:hover,
          .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-child:hover {
            opacity: .5; }
            @media screen and (max-width: 960px) {
              .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-parent:hover,
              .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-cat-types .case-cat-child:hover {
                opacity: 1; } }
      .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .case-info-var .case-company {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        letter-spacing: .06em;
        color: #22a7c3; }
      @media screen and (max-width: 768px) {
        .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .btn-area {
          display: none; } }
      .case-blog-list__inner .case-info-container .case-info-inner .case-info-top .btn-more {
        right: 0;
        left: auto; }
  .case-blog-list__inner.page-service {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media screen and (max-width: 768px) {
      .case-blog-list__inner.page-service {
        flex-direction: column; } }
    .case-blog-list__inner.page-service .case-blog-container {
      width: 48%;
      padding-top: 0;
      margin-bottom: 20px; }
      @media screen and (max-width: 768px) {
        .case-blog-list__inner.page-service .case-blog-container {
          width: 100%; } }

.pagination-container .pagination-inner {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 70px 0; }
  @media screen and (max-width: 600px) {
    .pagination-container .pagination-inner {
      padding: 30px 0; } }
  .pagination-container .pagination-inner .pager {
    display: block;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    color: #fff;
    letter-spacing: .03em;
    background-color: #22a7c3;
    margin-right: 3px; }
    .pagination-container .pagination-inner .pager.current {
      background-color: #1e2b69; }
    .pagination-container .pagination-inner .pager:hover {
      background-color: #1e2b69; }
    .pagination-container .pagination-inner .pager.pager-first {
      margin-left: 15px; }
    .pagination-container .pagination-inner .pager.pager-last {
      margin-right: 15px; }
    @media screen and (max-width: 600px) {
      .pagination-container .pagination-inner .pager {
        width: 45px;
        height: 45px; } }
  .pagination-container .pagination-inner .prev,
  .pagination-container .pagination-inner .next {
    display: block;
    width: 35px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8; }
    @media screen and (max-width: 600px) {
      .pagination-container .pagination-inner .prev,
      .pagination-container .pagination-inner .next {
        width: 25px;
        height: 45px; } }
    .pagination-container .pagination-inner .prev:hover,
    .pagination-container .pagination-inner .next:hover {
      background-color: #999999; }
  .pagination-container .pagination-inner .prev,
  .pagination-container .pagination-inner .next {
    display: flex;
    justify-content: center;
    align-items: center; }
    .pagination-container .pagination-inner .prev::before,
    .pagination-container .pagination-inner .next::before {
      content: "";
      width: 6px;
      height: 10px;
      background-repeat: no-repeat;
      background-size: contain; }
  .pagination-container .pagination-inner .prev::before {
    background-image: url(../../../assets/img/common/pagi-prev.png); }
  .pagination-container .pagination-inner .next::before {
    background-image: url(../../../assets/img/common/pagi-next.png); }
  .pagination-container .pagination-inner .first,
  .pagination-container .pagination-inner .last {
    display: block;
    width: 35px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8; }
    @media screen and (max-width: 600px) {
      .pagination-container .pagination-inner .first,
      .pagination-container .pagination-inner .last {
        width: 25px;
        height: 45px; } }
    .pagination-container .pagination-inner .first:hover,
    .pagination-container .pagination-inner .last:hover {
      background-color: #6c6c6c; }
    .pagination-container .pagination-inner .first::before,
    .pagination-container .pagination-inner .last::before {
      content: "";
      width: 13px;
      height: 11px;
      background-repeat: no-repeat;
      background-size: contain; }
  .pagination-container .pagination-inner .first {
    margin-right: 3px; }
    .pagination-container .pagination-inner .first::before {
      background-image: url(../../../assets/img/common/pagi-first.png); }
  .pagination-container .pagination-inner .last {
    margin-left: 3px; }
    .pagination-container .pagination-inner .last::before {
      background-image: url(../../../assets/img/common/pagi-last.png); }

.sns-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 600px) {
    .sns-icons {
      margin-top: calc( 35vw / 7.5);
      margin-bottom: 0; } }
  .sns-icons .sns-icon {
    display: block;
    width: 30px;
    margin-right: 15px;
    cursor: pointer; }
    .sns-icons .sns-icon:hover {
      opacity: .5; }
      @media screen and (max-width: 960px) {
        .sns-icons .sns-icon:hover {
          opacity: 1; } }
    @media screen and (max-width: 600px) {
      .sns-icons .sns-icon {
        width: calc(45vw / 7.5); } }
    .sns-icons .sns-icon:nth-last-of-type(1) {
      margin-right: 0; }
    .sns-icons .sns-icon img {
      width: 100%; }

.menu-btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #1e2b69;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  cursor: pointer; }
  .menu-btn:hover {
    opacity: .5; }
    @media screen and (max-width: 960px) {
      .menu-btn:hover {
        opacity: 1; } }
  @media screen and (max-width: 600px) {
    .menu-btn {
      width: 45px;
      height: 45px; } }
  .menu-btn-lines {
    height: 13px; }
    .menu-btn-lines .line {
      width: 15px;
      height: 2px;
      background-color: #fff; }
    .menu-btn-lines .line:nth-child(-n+2) {
      margin-bottom: 3px; }

.btn-area.padding {
  padding: 45px 0; }
  @media screen and (max-width: 768px) {
    .btn-area.padding {
      padding: calc(45vw / 7.5) 0; } }

.btn-area .btn-box {
  margin: auto; }

.btn-box {
  max-width: 270px;
  height: 50px; }
  .btn-box.long {
    max-width: 300px; }
  .btn-box-inner {
    display: block;
    width: 100%;
    height: 100%;
    position: relative; }
    .btn-box-inner .btn-more {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      min-width: 220px;
      line-height: 50px;
      border-radius: 25px;
      background-color: #22a7c3;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      opacity: 1;
      transition: all .8s; }
      .btn-box-inner .btn-more::after {
        content: "";
        position: absolute;
        width: 16px;
        height: 14px;
        top: 50%;
        transform: translateY(-50%);
        right: 10.8249%;
        background-repeat: no-repeat;
        background-size: contain; }
      .btn-box-inner .btn-more .arr {
        position: absolute;
        width: 16px;
        height: 13px;
        top: 50%;
        transform: translateY(-50%);
        right: 10.8249%; }
      .btn-box-inner .btn-more .hr-mark {
        position: absolute;
        width: 14px;
        top: 50%;
        left: 12%;
        transform: translateY(-50%); }
    .btn-box-inner .btn-more.df {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 1.2rem;
      letter-spacing: .03em; }
    .btn-box-inner .btn-more.hov {
      background-color: #1e2b69;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 400;
      font-size: 1.3rem;
      letter-spacing: .04em;
      opacity: 0; }
    .btn-box-inner .btn-more.en {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 1.2rem;
      letter-spacing: .03em; }
    .btn-box-inner .btn-more.ja {
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 400;
      font-size: 1.3rem;
      letter-spacing: .04em; }
    .btn-box-inner .btn-more.light:hover {
      background-color: #1e2b69; }

.case-desc-title .case-title {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  color: #6c6c6c;
  transition: all .8s;
  line-height: 1.4; }
  .case-desc-title .case-title.culture {
    font-size: 1.4rem;
    line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .case-desc-title .case-title {
      font-size: calc(28vw / 7.5); } }
  .case-desc-title .case-title .cate-link {
    color: #6c6c6c; }
    .case-desc-title .case-title .cate-link:hover {
      color: #000; }

.case-desc-tag .case-tags {
  margin-top: 15px;
  transition: all .8s; }
  .case-desc-tag .case-tags input {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    font-size: 0; }
  .case-desc-tag .case-tags .tag {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;
    color: #b5b5b5;
    margin-right: 10px;
    padding: 0;
    cursor: pointer; }
    .case-desc-tag .case-tags .tag:hover {
      color: #22a7c3; }

.btn-box:hover .btn-more.df {
  opacity: 0; }

.btn-box:hover .btn-more.hov {
  opacity: 1; }

main .main-main {
  margin-bottom: 316px;
  position: relative; }
  @media screen and (max-width: 600px) {
    main .main-main {
      margin-bottom: calc(598vw / 7.5); } }

.main-page-top {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100; }
  .main-page-top.back-on {
    visibility: hidden; }
  .main-page-top__inner {
    width: 100%;
    height: 100%;
    position: relative; }
    .main-page-top__inner.inside {
      padding-top: calc(45vw / 14.4);
      padding-bottom: calc(45vw / 14.4);
      width: 92.3611%;
      margin: auto; }
    .main-page-top__inner .main-video {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden; }
      .main-page-top__inner .main-video::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 28, 48, 0.5);
        z-index: 2; }
      .main-page-top__inner .main-video .yt-video {
        position: relative;
        z-index: 1;
        transform: scale(1.5); }

#player {
  transform: scale(1.4); }
  @media screen and (max-width: 1180px) {
    #player {
      transform: scale(2); } }
  @media screen and (max-width: 600px) {
    #player {
      transform: scale(6); } }

.main-top-body {
  position: absolute;
  top: 30vh;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 2; }
  @media screen and (max-width: 600px) {
    .main-top-body {
      top: calc(400vw / 7.5); } }
  .main-top-body.recruit {
    top: 38vh; }
  .main-top-body .main-msg {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: calc(70vw / 14.4);
    color: #fff;
    line-height: 1.3;
    width: 86.6666%;
    margin: 0 auto calc(70vw / 14.4) auto; }
    @media screen and (max-width: 600px) {
      .main-top-body .main-msg {
        font-size: calc(44vw / 7.5);
        width: 100%;
        margin-bottom: calc(82vw / 7.5); } }
    .main-top-body .main-msg.recruit {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 80px;
      letter-spacing: .04em; }
    .main-top-body .main-msg span {
      display: block;
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 2.2rem;
      letter-spacing: .03em;
      line-height: 1.3;
      margin-top: calc(30vw / 14.4); }
      @media screen and (max-width: 600px) {
        .main-top-body .main-msg span {
          font-size: calc(24vw / 7.5); } }
  .main-top-body .main-txt {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2;
    color: #fff;
    width: 90%;
    margin: auto; }
    @media screen and (max-width: 600px) {
      .main-top-body .main-txt {
        font-size: calc(24vw / 7.5);
        width: 80%;
        margin: auto; } }

.main-top-brank {
  height: 100vh; }

.main-content {
  background-color: #fff;
  position: relative;
  z-index: 300; }
  .main-content.top {
    z-index: 100; }

.bread-nav {
  padding-top: calc(40vw / 14.4);
  padding-bottom: calc(20vw / 14.4);
  width: 90%;
  margin: auto; }
  .bread-nav__inner {
    max-width: 1080px;
    margin: auto; }
    .bread-nav__inner #bread_crumb ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
    .bread-nav__inner #bread_crumb li {
      display: flex;
      align-items: center;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 700;
      font-size: 1.1rem;
      color: #6c6c6c; }
      @media screen and (max-width: 768px) {
        .bread-nav__inner #bread_crumb li {
          line-height: 1.4; } }
      .bread-nav__inner #bread_crumb li::after {
        content: ">";
        display: block;
        margin: 0 10px;
        color: #6c6c6c; }
      .bread-nav__inner #bread_crumb li:nth-last-child(1)::after {
        display: none; }
      .bread-nav__inner #bread_crumb li a {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 700;
        font-size: 1.1rem;
        color: #6c6c6c; }

.section .section-head {
  padding: 60px 0 40px 0; }
  @media screen and (max-width: 960px) {
    .section .section-head {
      padding: calc(50vw /7.5) 0 calc(50vw /7.5) 0; } }
  @media screen and (max-width: 600px) {
    .section .section-head {
      padding: calc(60vw /7.5) 0 calc(60vw /7.5) 0; } }
  .section .section-head .section-title {
    text-align: center;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: .1em; }
    @media screen and (max-width: 768px) {
      .section .section-head .section-title {
        font-size: calc(30vw / 7.5);
        line-height: 1.4; } }
    @media screen and (max-width: 600px) {
      .section .section-head .section-title {
        font-size: 1.6rem; } }
    .section .section-head .section-title span {
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 1.1rem;
      margin-top: 1rem;
      text-transform: uppercase; }
      @media screen and (max-width: 600px) {
        .section .section-head .section-title span {
          font-size: 1.1rem; } }

.page-content {
  width: 90%;
  margin: auto; }
  @media screen and (max-width: 768px) {
    .page-content {
      width: 86.666%; } }

.section-article {
  background-color: #f8f8f8; }
  .section-article.single {
    padding-bottom: 50px; }
    @media screen and (max-width: 768px) {
      .section-article.single {
        padding-bottom: 0; } }
  .section-article__inner {
    display: flex; }
    @media screen and (max-width: 960px) {
      .section-article__inner {
        flex-direction: column; } }
    .section-article__inner .article-part {
      width: 50%; }
      @media screen and (max-width: 960px) {
        .section-article__inner .article-part {
          width: 100%; } }
      .section-article__inner .article-part .section-head {
        padding: 50px 0 50px 47px; }
        @media screen and (max-width: 960px) {
          .section-article__inner .article-part .section-head {
            padding: calc(50vw / 9.6) 0 calc(50vw / 9.6) calc(45vw / 9.6); } }
        @media screen and (max-width: 600px) {
          .section-article__inner .article-part .section-head {
            padding: calc(60vw /7.5) 0 calc(60vw /7.5) calc(45vw /7.5); } }
        .section-article__inner .article-part .section-head .section-title {
          color: #fff;
          text-align: left; }
      .section-article__inner .article-part .article-list__inner {
        display: flex;
        flex-wrap: wrap; }
        .section-article__inner .article-part .article-list__inner .article-content {
          display: block;
          position: relative;
          height: 50%;
          width: 50%;
          cursor: pointer; }
          @media screen and (max-width: 600px) {
            .section-article__inner .article-part .article-list__inner .article-content {
              width: 100%; } }
          .section-article__inner .article-part .article-list__inner .article-content .article-img-box {
            width: 100%;
            padding-top: 83%;
            position: relative; }
            .section-article__inner .article-part .article-list__inner .article-content .article-img-box::before {
              position: absolute;
              content: '';
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              background-repeat: no-repeat;
              background-size: cover;
              z-index: 1; }
            .section-article__inner .article-part .article-list__inner .article-content .article-img-box img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover; }
          .section-article__inner .article-part .article-list__inner .article-content .content-hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            display: none;
            opacity: 0;
            transition: all .5s;
            z-index: 2; }
            .section-article__inner .article-part .article-list__inner .article-content .content-hover .hover-circle {
              width: 37.5%;
              padding-top: 37.5%;
              position: relative; }
              .section-article__inner .article-part .article-list__inner .article-content .content-hover .hover-circle__inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #22a7c3;
                display: flex;
                justify-content: center;
                align-items: center; }
                .section-article__inner .article-part .article-list__inner .article-content .content-hover .hover-circle__inner .circle-content .hover-arr {
                  display: block;
                  margin: 0 auto 10px auto;
                  width: 18px;
                  height: 16px; }
                .section-article__inner .article-part .article-list__inner .article-content .content-hover .hover-circle__inner .circle-content .circle-txt {
                  font-family: 'Montserrat', sans-serif;
                  font-weight: 700;
                  color: #fff;
                  font-size: calc(13vw / 14.4);
                  letter-spacing: .03em; }
          .section-article__inner .article-part .article-list__inner .article-content:hover .content-hover {
            display: flex;
            animation: hover-fade-in .5s forwards; }
          .section-article__inner .article-part .article-list__inner .article-content::before {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            background-repeat: no-repeat;
            background-size: cover; }
          .section-article__inner .article-part .article-list__inner .article-content img {
            width: 100%;
            object-fit: cover;
            vertical-align: bottom; }
          .section-article__inner .article-part .article-list__inner .article-content .article-info {
            position: absolute;
            bottom: calc(30vw / 14.4);
            left: 50%;
            transform: translateX(-50%);
            width: 85%;
            z-index: 2; }
            .section-article__inner .article-part .article-list__inner .article-content .article-info-top {
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              color: #22a7c3;
              font-size: 1.2rem;
              letter-spacing: .03em;
              margin-bottom: 8px; }
            .section-article__inner .article-part .article-list__inner .article-content .article-info .article-title {
              font-family: 'Noto Sans JP', sans-serif;
              font-weight: 500;
              color: #fff;
              font-size: calc(14vw / 14.4);
              line-height: 1.86; }
              @media screen and (max-width: 960px) {
                .section-article__inner .article-part .article-list__inner .article-content .article-info .article-title {
                  font-size: calc(19vw / 9.6); } }
              @media screen and (max-width: 600px) {
                .section-article__inner .article-part .article-list__inner .article-content .article-info .article-title {
                  font-size: calc(24vw / 6); } }
              .section-article__inner .article-part .article-list__inner .article-content .article-info .article-title span {
                display: block; }
    @media screen and (max-width: 960px) {
      .section-article__inner .article-part.article-pick {
        margin-bottom: calc(70vw / 9.6); } }
    .section-article__inner .article-part.article-pick .section-head {
      background-color: #1e2b69; }
    .section-article__inner .article-part.article-relate .section-head {
      background-color: #22a7c3; }
  .section-article .btn-area {
    background-color: #f8f8f8; }

.company-info-container {
  margin-bottom: 70px; }
  @media screen and (max-width: 768px) {
    .company-info-container {
      margin-bottom: 40px; } }
  @media screen and (max-width: 600px) {
    .company-info-container {
      margin-bottom: 20px; } }
  .company-info-container .compan-info__inner {
    padding: 35px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 600px) {
      .company-info-container .compan-info__inner {
        padding: 20px; } }
    .company-info-container .compan-info__inner .company-info {
      width: 77%; }
      .company-info-container .compan-info__inner .company-info .compnay-name {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 700;
        font-size: 1.8rem;
        letter-spacing: .04em;
        color: #000;
        margin-bottom: 25px; }
        @media screen and (max-width: 600px) {
          .company-info-container .compan-info__inner .company-info .compnay-name {
            font-size: calc( 28vw  / 7.5);
            line-height: 1.4;
            margin-bottom: calc( 24vw  / 7.5); } }
      .company-info-container .compan-info__inner .company-info .text-body {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        color: #6c6c6c;
        line-height: 1.8;
        margin-bottom: 25px; }
      .company-info-container .compan-info__inner .company-info .info-box {
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        color: #6c6c6c; }
        @media screen and (max-width: 768px) {
          .company-info-container .compan-info__inner .company-info .info-box {
            width: calc(90vw - 70px); } }
        @media screen and (max-width: 600px) {
          .company-info-container .compan-info__inner .company-info .info-box {
            width: calc(90vw - 40px); } }
        .company-info-container .compan-info__inner .company-info .info-box .info-table {
          display: flex; }
          @media screen and (max-width: 600px) {
            .company-info-container .compan-info__inner .company-info .info-box .info-table {
              margin-bottom: 8px; } }
          .company-info-container .compan-info__inner .company-info .info-box .info-table .info-name {
            width: 14%;
            line-height: 1.8; }
            @media screen and (max-width: 768px) {
              .company-info-container .compan-info__inner .company-info .info-box .info-table .info-name {
                width: 20%; } }
            @media screen and (max-width: 600px) {
              .company-info-container .compan-info__inner .company-info .info-box .info-table .info-name {
                width: 25%; } }
          .company-info-container .compan-info__inner .company-info .info-box .info-table .info-txt {
            width: 86%;
            line-height: 1.4; }
            @media screen and (max-width: 768px) {
              .company-info-container .compan-info__inner .company-info .info-box .info-table .info-txt {
                width: 80%; } }
    .company-info-container .compan-info__inner .company-logo-box {
      width: 20%; }
      @media screen and (max-width: 768px) {
        .company-info-container .compan-info__inner .company-logo-box {
          height: 50%; } }
      .company-info-container .compan-info__inner .company-logo-box img {
        width: 100%;
        object-fit: cover; }

.move-btn-container {
  margin-bottom: 70px; }
  @media screen and (max-width: 600px) {
    .move-btn-container {
      margin-bottom: calc(50vw / 7.5); } }
  .move-btn-container .move-btn-box {
    width: 210px;
    height: 70px;
    margin: auto;
    display: flex;
    cursor: pointer; }
    .move-btn-container .move-btn-box .move-box {
      display: block;
      position: relative; }
      .move-btn-container .move-btn-box .move-box .arr {
        position: absolute;
        display: block;
        width: 16px;
        height: 13px;
        top: 50%;
        transform: translateY(-50%); }
      .move-btn-container .move-btn-box .move-box .move-txt {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #fff;
        letter-spacing: .03em; }
        .move-btn-container .move-btn-box .move-box .move-txt.prev {
          left: 80px; }
        .move-btn-container .move-btn-box .move-box .move-txt.next {
          right: 80px; }
    .move-btn-container .move-btn-box .prev-box {
      width: 33.3333%;
      height: 100%;
      background-color: #1e2b69;
      transition: all .5s; }
      .move-btn-container .move-btn-box .prev-box.long {
        width: 66.6666%; }
      .move-btn-container .move-btn-box .prev-box .arr {
        left: 27px; }
    .move-btn-container .move-btn-box .next-box {
      width: 66.6666%;
      height: 100%;
      background-color: #22a7c3;
      transition: all .5s; }
      .move-btn-container .move-btn-box .next-box.short {
        width: 33.3333%; }
      .move-btn-container .move-btn-box .next-box .arr {
        right: 27px; }
  .move-btn-container .back-btn {
    padding-top: 30px; }
    .move-btn-container .back-btn .back-top {
      text-align: center; }
      .move-btn-container .back-btn .back-top .top-link {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        color: #22a7c3;
        letter-spacing: .03em; }

.recruit-section.section-inter {
  padding-top: 60px; }
  @media screen and (max-width: 600px) {
    .recruit-section.section-inter {
      padding-top: 0; } }
  .recruit-section.section-inter .recruit-section__inner {
    width: 90%;
    max-width: 1080px;
    margin: auto; }
    .recruit-section.section-inter .recruit-section__inner .recruit-section-title {
      margin-bottom: 48px; }
    .recruit-section.section-inter .recruit-section__inner .recruit-section-body {
      padding: 0 90px; }
      @media screen and (max-width: 600px) {
        .recruit-section.section-inter .recruit-section__inner .recruit-section-body {
          padding: 0; } }
      .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content {
          display: block;
          width: 29%;
          cursor: pointer;
          margin-bottom: 35px; }
          @media screen and (max-width: 600px) {
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content {
              width: 49%; } }
          .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner {
            position: relative; }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .inter-img-box {
              width: 100%;
              padding-top: 112%;
              position: relative; }
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .inter-img-box img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .content-hover {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
              display: none;
              opacity: 0;
              transition: all .5s;
              z-index: 2; }
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .content-hover .hover-circle {
                width: 54%;
                padding-top: 54%;
                position: relative; }
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .content-hover .hover-circle__inner {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  background-color: #22a7c3;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                  .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .content-hover .hover-circle__inner .circle-content .hover-arr {
                    display: block;
                    margin: 0 auto 10px auto;
                    width: 18px;
                    height: 16px; }
                  .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner .content-hover .hover-circle__inner .circle-content .circle-txt {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 700;
                    color: #fff;
                    font-size: calc(13vw / 14.4);
                    letter-spacing: .03em; }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content__inner:hover .content-hover {
              display: flex;
              animation: hover-fade-in .5s forwards; }
          .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info {
            padding: 18px;
            background-color: #22a7c3;
            width: 92%;
            max-width: 216px;
            position: relative;
            margin-top: -17%;
            z-index: 3; }
            @media screen and (max-width: 600px) {
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info {
                padding: calc(24vw / 7.5); } }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-branch,
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-sort {
              font-family: 'Noto Sans JP', sans-serif;
              font-weight: 500;
              font-size: 1.1rem;
              letter-spacing: .02em;
              line-height: 1.8;
              color: #fff; }
              @media screen and (max-width: 1180px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-branch,
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-sort {
                  font-size: calc(13vw / 7.5);
                  line-height: 1.3; } }
              @media screen and (max-width: 600px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-branch,
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-sort {
                  font-size: calc(18vw / 7.5); } }
            @media screen and (max-width: 1180px) {
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-branch {
                margin-bottom: 5px; } }
            @media screen and (max-width: 1180px) {
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-sort {
                margin-bottom: 7px; } }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .supple {
              font-family: 'Noto Sans JP', sans-serif;
              font-weight: 500;
              font-size: 1.1rem;
              letter-spacing: .02em;
              line-height: 1.6;
              color: #fff; }
              @media screen and (max-width: 1180px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .supple {
                  font-size: calc(12vw / 7.5); } }
              @media screen and (max-width: 600px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .supple {
                  font-size: calc(15vw / 7.5); } }
            .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-name {
              margin-top: 18px;
              font-family: 'Noto Sans JP', sans-serif;
              font-weight: 500;
              font-size: 1.8rem;
              color: #000;
              letter-spacing: .1em;
              line-height: 1.22; }
              @media screen and (max-width: 1180px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-name {
                  font-size: calc(14vw / 7.5); } }
              @media screen and (max-width: 600px) {
                .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-name {
                  font-size: calc(24vw / 7.5);
                  margin-top: calc(25vw / 7.5); } }
              .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-name span {
                margin-top: 6px;
                display: block;
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                font-size: 1rem;
                letter-spacing: .03em;
                color: #6c6c6c; }
                @media screen and (max-width: 600px) {
                  .recruit-section.section-inter .recruit-section__inner .recruit-section-body .inter-body-container .inter-content .member-info .member-name span {
                    font-size: calc(15vw / 7.5); } }

@keyframes hover-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.common-contact-area {
  background-color: #f8f8f8;
  padding: 60px 0; }
  @media screen and (max-width: 768px) {
    .common-contact-area {
      padding: calc(60vw / 7.5) 0; } }
  .common-contact-area__inner {
    width: 90%;
    max-width: 1080px;
    margin: auto; }
    @media screen and (max-width: 960px) {
      .common-contact-area__inner {
        width: 90%; } }
    @media screen and (max-width: 768px) {
      .common-contact-area__inner {
        width: 86.6666%;
        margin: auto; } }
    .common-contact-area__inner .btn-box {
      width: 100%;
      max-width: 100%; }
      @media screen and (max-width: 600px) {
        .common-contact-area__inner .btn-box-inner .btn-more {
          width: 100%;
          height: 100%; } }
      .common-contact-area__inner .btn-box-inner .btn-more img.arr {
        left: 50%;
        transform: translate(calc(-50% + 45px), -50%);
        right: auto;
        margin-left: 60px; }
      .common-contact-area__inner .btn-box-inner .btn-more::after {
        display: none !important; }

footer {
  height: 316px;
  background-color: #011c30;
  width: 100%; }
  footer.fixed {
    position: fixed;
    bottom: 0;
    z-index: 50; }
  @media screen and (max-width: 600px) {
    footer {
      height: calc(598vw / 7.5); } }
  footer .footer__inner {
    padding: 80px 0 40px 0; }
    @media screen and (max-width: 600px) {
      footer .footer__inner {
        width: 90%;
        margin: auto;
        padding: calc(73vw / 7.5) 0 0 0; } }
    footer .footer__inner .footer-logo {
      text-align: center;
      margin-bottom: 40px;
      height: 37px;
      cursor: pointer; }
      footer .footer__inner .footer-logo:hover {
        opacity: .5; }
        @media screen and (max-width: 960px) {
          footer .footer__inner .footer-logo:hover {
            opacity: 1; } }
      @media screen and (max-width: 600px) {
        footer .footer__inner .footer-logo {
          text-align: left;
          margin-bottom: calc(47vw / 7.5); } }
      footer .footer__inner .footer-logo svg {
        height: 100%; }
        @media screen and (max-width: 600px) {
          footer .footer__inner .footer-logo svg {
            width: calc(225vw / 6);
            height: auto; } }
        footer .footer__inner .footer-logo svg polygon,
        footer .footer__inner .footer-logo svg path,
        footer .footer__inner .footer-logo svg rect {
          fill: #fff; }
    footer .footer__inner .footer-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px; }
      @media screen and (max-width: 600px) {
        footer .footer__inner .footer-icons.pc {
          display: none; } }
      footer .footer__inner .footer-icons.sp {
        display: none; }
        @media screen and (max-width: 600px) {
          footer .footer__inner .footer-icons.sp {
            display: flex;
            margin-top: calc( 35vw / 7.5); } }
      @media screen and (max-width: 600px) {
        footer .footer__inner .footer-icons {
          justify-content: flex-start;
          margin-bottom: calc( 35vw / 7.5); } }
      footer .footer__inner .footer-icons .sns-icon {
        width: 30px;
        margin-right: 15px;
        cursor: pointer; }
        footer .footer__inner .footer-icons .sns-icon:hover {
          opacity: .5; }
          @media screen and (max-width: 960px) {
            footer .footer__inner .footer-icons .sns-icon:hover {
              opacity: 1; } }
        @media screen and (max-width: 600px) {
          footer .footer__inner .footer-icons .sns-icon {
            width: calc(45vw / 7.5); } }
        footer .footer__inner .footer-icons .sns-icon:nth-last-of-type(1) {
          margin-right: 0; }
        footer .footer__inner .footer-icons .sns-icon img {
          width: 100%; }
    footer .footer__inner .footer-nav {
      display: flex;
      justify-content: center;
      margin-bottom: 40px; }
      @media screen and (max-width: 600px) {
        footer .footer__inner .footer-nav {
          justify-content: flex-start;
          margin-bottom: calc(58vw / 7.5); } }
      footer .footer__inner .footer-nav .f-nav-container {
        display: flex;
        justify-content: center;
        width: 100%; }
        @media screen and (max-width: 600px) {
          footer .footer__inner .footer-nav .f-nav-container .f-nav-part {
            display: flex;
            flex-direction: column;
            justify-content: initial;
            width: 50%; } }
        footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item {
          color: #fff;
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          font-size: 1.2rem;
          letter-spacing: 0.12em;
          margin-right: 20px;
          cursor: pointer;
          cursor: pointer; }
          footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item:hover {
            opacity: .5; }
            @media screen and (max-width: 960px) {
              footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item:hover {
                opacity: 1; } }
          @media screen and (max-width: 768px) {
            footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item {
              margin-right: 10px; } }
          @media screen and (max-width: 600px) {
            footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item {
              font-size: calc(24vw / 7.5);
              margin-bottom: calc(31vw / 7.5); }
              footer .footer__inner .footer-nav .f-nav-container .f-nav-part .f-nav-item:nth-last-child(1) {
                margin-bottom: 0; } }
        footer .footer__inner .footer-nav .f-nav-container .f-nav-part.f-nav-right .f-nav-item:nth-last-child(1) {
          margin-right: 0px; }
    footer .footer__inner .foooter-line {
      width: 100%;
      height: 2px;
      background-color: #fff; }
    footer .footer__inner .copy {
      color: #fff;
      font-family: 'Roboto', sans-serif;
      font-weight: 900;
      font-size: 1rem;
      letter-spacing: 0.12em;
      text-align: center; }
      @media screen and (max-width: 600px) {
        footer .footer__inner .copy {
          text-align: left;
          font-size: 1.2rem; } }
      @media screen and (max-width: 480px) {
        footer .footer__inner .copy {
          font-size: calc(12vw / 3.7); } }

.section-column.top-page .column-list-def {
  overflow: hidden; }
  @media screen and (max-width: 1180px) {
    .section-column.top-page .column-list-def {
      display: none; } }
  .section-column.top-page .column-list-def__inner {
    display: flex;
    height: calc(600vw / 14.4); }
    .section-column.top-page .column-list-def__inner .column-container-left {
      width: 50%;
      height: 100%; }
      .section-column.top-page .column-list-def__inner .column-container-left .column-content {
        display: block;
        width: 100%;
        padding-top: 83.4722%;
        position: relative;
        cursor: pointer; }
        .section-column.top-page .column-list-def__inner .column-container-left .column-content .content-hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          opacity: 0;
          transition: all .5s; }
          .section-column.top-page .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle {
            width: 37.5%;
            padding-top: 37.5%;
            position: relative; }
            .section-column.top-page .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #22a7c3;
              display: flex;
              justify-content: center;
              align-items: center; }
              .section-column.top-page .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content::before {
                content: "";
                display: block;
                margin: 0 auto 10px auto;
                width: 30px;
                height: 26px;
                background-image: url(../img/common/arr.png);
                background-repeat: no-repeat;
                background-size: contain; }
              .section-column.top-page .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content .circle-txt {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                color: #fff;
                font-size: calc(20vw / 14.4);
                letter-spacing: .03em; }
        .section-column.top-page .column-list-def__inner .column-container-left .column-content:hover .content-hover {
          display: flex;
          animation: hover-fade-in .5s forwards; }
        .section-column.top-page .column-list-def__inner .column-container-left .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: 1; }
        .section-column.top-page .column-list-def__inner .column-container-left .column-content img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          vertical-align: bottom; }
        .section-column.top-page .column-list-def__inner .column-container-left .column-content .column-info {
          position: absolute;
          bottom: 30px;
          left: 6%;
          z-index: 2; }
          .section-column.top-page .column-list-def__inner .column-container-left .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 13px; }
          .section-column.top-page .column-list-def__inner .column-container-left .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: 1.4rem;
            line-height: 1.86; }
            .section-column.top-page .column-list-def__inner .column-container-left .column-content .column-info .column-title span {
              display: block; }
    .section-column.top-page .column-list-def__inner .column-container-right {
      width: 50%;
      display: flex;
      flex-wrap: wrap; }
      .section-column.top-page .column-list-def__inner .column-container-right .column-content {
        position: relative;
        height: 50%;
        width: 50%;
        cursor: pointer; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content:nth-of-type(1) {
          display: none; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content .content-hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          opacity: 0;
          transition: all .5s;
          z-index: 3; }
          .section-column.top-page .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle {
            width: 37.5%;
            padding-top: 37.5%;
            position: relative; }
            .section-column.top-page .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #22a7c3;
              display: flex;
              justify-content: center;
              align-items: center; }
              .section-column.top-page .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content::before {
                content: "";
                display: block;
                margin: 0 auto 10px auto;
                width: 18px;
                height: 16px;
                background-image: url(../img/common/arr.png);
                background-repeat: no-repeat;
                background-size: contain; }
              .section-column.top-page .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content .circle-txt {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                color: #fff;
                font-size: calc(13vw / 14.4);
                letter-spacing: .03em; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content:hover .content-hover {
          display: flex;
          animation: hover-fade-in .5s forwards; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          background-repeat: no-repeat;
          background-size: cover; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-img-box {
          width: 100%;
          padding-top: 83.4722%;
          position: relative; }
          .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-img-box img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom; }
        .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-info {
          position: absolute;
          bottom: calc(30vw / 14.4);
          left: 50%;
          transform: translateX(-50%);
          width: 85%; }
          .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 8px; }
          .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: calc(14vw / 14.4);
            line-height: 1.86; }
            .section-column.top-page .column-list-def__inner .column-container-right .column-content .column-info .column-title span {
              display: block; }

.section-column.top-page .column-list-sub {
  display: none; }
  @media screen and (max-width: 1180px) {
    .section-column.top-page .column-list-sub {
      display: block; } }
  .section-column.top-page .column-list-sub__inner {
    margin: auto; }
    .section-column.top-page .column-list-sub__inner .column-container {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 600px) {
        .section-column.top-page .column-list-sub__inner .column-container {
          flex-direction: column; } }
      .section-column.top-page .column-list-sub__inner .column-container .column-content {
        width: 50%;
        height: calc(360vw / 8);
        position: relative; }
        @media screen and (max-width: 600px) {
          .section-column.top-page .column-list-sub__inner .column-container .column-content {
            width: 100%;
            height: calc(626vw / 7.5); } }
        .section-column.top-page .column-list-sub__inner .column-container .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          background-repeat: no-repeat;
          background-size: cover; }
        .section-column.top-page .column-list-sub__inner .column-container .column-content img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          vertical-align: bottom; }
        .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info {
          position: absolute;
          bottom: calc(30vw / 14.4);
          left: 50%;
          transform: translateX(-50%);
          width: 85%; }
          @media screen and (max-width: 600px) {
            .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info {
              bottom: calc(100vw / 14.4); } }
          .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 8px; }
            @media screen and (max-width: 600px) {
              .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info-top {
                font-size: calc(22vw / 7.5); } }
          .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: calc(24vw / 14.4);
            line-height: 1.86; }
            @media screen and (max-width: 600px) {
              .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info .column-title {
                font-size: calc(28vw / 7.5);
                letter-spacing: .08em; } }
            .section-column.top-page .column-list-sub__inner .column-container .column-content .column-info .column-title span {
              display: block; }

.section-column.top-page .btn-area {
  padding: 45px 0; }
  @media screen and (max-width: 600px) {
    .section-column.top-page .btn-area {
      padding: calc(70vw / 7.5) 0; } }

.section-column.page-column-list .column-list-def {
  overflow: initial; }
  @media screen and (max-width: 1180px) {
    .section-column.page-column-list .column-list-def {
      display: none; } }
  .section-column.page-column-list .column-list-def__inner {
    display: flex;
    height: auto; }
    .section-column.page-column-list .column-list-def__inner .column-container-left {
      width: 50%;
      height: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: 125px; }
      @media screen and (max-width: 960px) {
        .section-column.page-column-list .column-list-def__inner .column-container-left {
          top: 134px; } }
      .section-column.page-column-list .column-list-def__inner .column-container-left .column-content {
        position: relative;
        cursor: pointer;
        display: block; }
        .section-column.page-column-list .column-list-def__inner .column-container-left .column-content__inner {
          width: 100%;
          padding-top: 83.4722%;
          position: relative; }
          .section-column.page-column-list .column-list-def__inner .column-container-left .column-content__inner img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom; }
        .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          opacity: 0;
          transition: all .5s;
          z-index: 3; }
          .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle {
            width: 37.5%;
            padding-top: 37.5%;
            position: relative; }
            .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #22a7c3;
              display: flex;
              justify-content: center;
              align-items: center; }
              .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content::before {
                display: none; }
              .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content .arr {
                content: "";
                display: block;
                margin: 0 auto 10px auto;
                width: 30px;
                height: 26px;
                position: relative; }
                .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content .arr img {
                  width: 30px;
                  height: 26px;
                  object-fit: contain; }
              .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .content-hover .hover-circle__inner .circle-content .circle-txt {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                color: #fff;
                font-size: calc(20vw / 14.4);
                letter-spacing: .03em; }
        .section-column.page-column-list .column-list-def__inner .column-container-left .column-content:hover .content-hover {
          display: flex;
          animation: hover-fade-in .5s forwards; }
        .section-column.page-column-list .column-list-def__inner .column-container-left .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          z-index: 1; }
        .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .column-info {
          position: absolute;
          bottom: 30px;
          left: 6%;
          z-index: 2; }
          .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 13px; }
          .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: 1.4rem;
            line-height: 1.86; }
            .section-column.page-column-list .column-list-def__inner .column-container-left .column-content .column-info .column-title span {
              display: block; }
    .section-column.page-column-list .column-list-def__inner .column-container-right {
      width: 50%;
      display: flex;
      flex-wrap: wrap; }
      .section-column.page-column-list .column-list-def__inner .column-container-right .column-content {
        position: relative;
        height: auto;
        width: 50%;
        cursor: pointer; }
        .section-column.page-column-list .column-list-def__inner .column-container-right .column-content__inner {
          width: 100%;
          padding-top: 83.4722%;
          position: relative; }
          .section-column.page-column-list .column-list-def__inner .column-container-right .column-content__inner::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            background-repeat: no-repeat; }
          .section-column.page-column-list .column-list-def__inner .column-container-right .column-content__inner img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom; }
        .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          opacity: 0;
          transition: all .5s;
          z-index: 3; }
          .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle {
            width: 37.5%;
            padding-top: 37.5%;
            position: relative; }
            .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: #22a7c3;
              display: flex;
              justify-content: center;
              align-items: center; }
              .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content::before {
                display: none; }
              .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content .arr {
                content: "";
                display: block;
                margin: 0 auto 10px auto;
                width: 18px;
                height: 16px;
                position: relative; }
                .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content .arr img {
                  width: 18px;
                  height: 16px;
                  object-fit: contain; }
              .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .content-hover .hover-circle__inner .circle-content .circle-txt {
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
                color: #fff;
                font-size: calc(13vw / 14.4);
                letter-spacing: .03em; }
        .section-column.page-column-list .column-list-def__inner .column-container-right .column-content:hover .content-hover {
          display: flex;
          animation: hover-fade-in .5s forwards; }
        .section-column.page-column-list .column-list-def__inner .column-container-right .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          background-repeat: no-repeat;
          background-size: cover;
          display: none;
          z-index: 1; }
        .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .column-info {
          position: absolute;
          bottom: calc(30vw / 14.4);
          left: 50%;
          transform: translateX(-50%);
          width: 85%;
          z-index: 2; }
          .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 8px; }
          .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: calc(14vw / 14.4);
            line-height: 1.86; }
            .section-column.page-column-list .column-list-def__inner .column-container-right .column-content .column-info .column-title span {
              display: block; }

.section-column.page-column-list .column-list-sub {
  display: none; }
  @media screen and (max-width: 1180px) {
    .section-column.page-column-list .column-list-sub {
      display: block; } }
  .section-column.page-column-list .column-list-sub__inner {
    margin: auto; }
    .section-column.page-column-list .column-list-sub__inner .column-container {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 600px) {
        .section-column.page-column-list .column-list-sub__inner .column-container {
          flex-direction: column; } }
      .section-column.page-column-list .column-list-sub__inner .column-container .column-content {
        width: 50%;
        height: calc(360vw / 8);
        position: relative; }
        @media screen and (max-width: 600px) {
          .section-column.page-column-list .column-list-sub__inner .column-container .column-content {
            width: 100%;
            height: calc(626vw / 7.5); } }
        .section-column.page-column-list .column-list-sub__inner .column-container .column-content::before {
          position: absolute;
          content: '';
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          background-repeat: no-repeat;
          background-size: cover; }
        .section-column.page-column-list .column-list-sub__inner .column-container .column-content img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          vertical-align: bottom; }
        .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info {
          position: absolute;
          bottom: calc(30vw / 14.4);
          left: 50%;
          transform: translateX(-50%);
          width: 85%; }
          @media screen and (max-width: 600px) {
            .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info {
              bottom: calc(100vw / 14.4); } }
          .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info-top {
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            color: #22a7c3;
            font-size: 1.2rem;
            letter-spacing: .03em;
            margin-bottom: 8px; }
            @media screen and (max-width: 600px) {
              .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info-top {
                font-size: calc(22vw / 7.5); } }
          .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info .column-title {
            font-family: 'Noto Sans JP', sans-serif;
            font-weight: 500;
            color: #fff;
            font-size: calc(24vw / 14.4);
            line-height: 1.86; }
            @media screen and (max-width: 600px) {
              .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info .column-title {
                font-size: calc(28vw / 7.5);
                letter-spacing: .08em; } }
            .section-column.page-column-list .column-list-sub__inner .column-container .column-content .column-info .column-title span {
              display: block; }

.section-column.page-column-list .btn-area {
  padding: 45px 0; }
  @media screen and (max-width: 600px) {
    .section-column.page-column-list .btn-area {
      padding: calc(70vw / 7.5) 0; } }

.section-news.page-news-list .news-list {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  border-top: 1px solid #1e2b69; }
  .section-news.page-news-list .news-list__inner {
    padding-top: 10px; }
    .section-news.page-news-list .news-list__inner .news-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 30px 0;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: .08em;
      border-bottom: 1px solid #dfdfdf; }
      @media screen and (max-width: 768px) {
        .section-news.page-news-list .news-list__inner .news-item {
          flex-wrap: wrap; } }
      @media screen and (max-width: 600px) {
        .section-news.page-news-list .news-list__inner .news-item {
          font-size: 1.4rem; } }
      .section-news.page-news-list .news-list__inner .news-item .post-date {
        width: 120px;
        line-height: 1.6;
        color: #6c6c6c; }
      .section-news.page-news-list .news-list__inner .news-item .post-cate {
        width: 135px;
        line-height: 1.6;
        color: #6c6c6c; }
        @media screen and (max-width: 600px) {
          .section-news.page-news-list .news-list__inner .news-item .post-cate {
            width: 100%; } }
      .section-news.page-news-list .news-list__inner .news-item .post-title {
        width: 80%;
        color: #22a7c3;
        line-height: 1.6;
        cursor: pointer;
        cursor: pointer; }
        .section-news.page-news-list .news-list__inner .news-item .post-title:hover {
          opacity: .5; }
          @media screen and (max-width: 960px) {
            .section-news.page-news-list .news-list__inner .news-item .post-title:hover {
              opacity: 1; } }
        @media screen and (max-width: 768px) {
          .section-news.page-news-list .news-list__inner .news-item .post-title {
            width: 100%;
            margin-top: 8px; } }

.case-slider {
  width: 100%;
  overflow: hidden; }
  .case-slider .case-slide-container {
    overflow-y: visible;
    overflow-x: visible;
    width: 76.5972%;
    position: relative;
    height: calc(420vw / 14.4); }
    @media screen and (max-width: 600px) {
      .case-slider .case-slide-container {
        width: 100%;
        height: calc(290vw / 7.5); } }
    .case-slider .case-slide-container .case-slide-wrap .case-slide {
      max-height: 1103px;
      height: 100%; }
      .case-slider .case-slide-container .case-slide-wrap .case-slide.swiper-slide-prev, .case-slider .case-slide-container .case-slide-wrap .case-slide.swiper-slide-next {
        opacity: 0.6; }
      .case-slider .case-slide-container .case-slide-wrap .case-slide img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .case-slider .case-slide-container .swiper-button-prev::after,
    .case-slider .case-slide-container .swiper-button-next::after {
      display: none; }
    .case-slider .case-slide-container .swiper-button-prev {
      width: calc( 25vw / 14.4 + 45px);
      height: calc( 25vw / 14.4 + 45px);
      background-color: #1e2b69;
      top: auto;
      bottom: 0;
      left: auto;
      right: calc( 25vw / 14.4 + 45px);
      transform: translateY(50%); }
      @media screen and (max-width: 600px) {
        .case-slider .case-slide-container .swiper-button-prev {
          display: none; } }
      .case-slider .case-slide-container .swiper-button-prev .arrow {
        display: inline-block;
        position: relative;
        color: #000;
        padding: 0 0 0 16px;
        color: #000;
        vertical-align: middle;
        font-size: 25px; }
        .case-slider .case-slide-container .swiper-button-prev .arrow::before, .case-slider .case-slide-container .swiper-button-prev .arrow::after {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          vertical-align: middle; }
        .case-slider .case-slide-container .swiper-button-prev .arrow::before {
          left: 0;
          width: 15px;
          height: 1px;
          background: #fff; }
        .case-slider .case-slide-container .swiper-button-prev .arrow::after {
          left: 1px;
          width: 8px;
          height: 8px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }
    .case-slider .case-slide-container .swiper-button-next {
      width: calc( 25vw / 14.4 + 45px);
      height: calc( 25vw / 14.4 + 45px);
      background-color: #22a7c3;
      right: 0;
      top: auto;
      bottom: 0;
      transform: translateY(50%); }
      @media screen and (max-width: 600px) {
        .case-slider .case-slide-container .swiper-button-next {
          display: none; } }
      .case-slider .case-slide-container .swiper-button-next .arrow {
        display: inline-block;
        position: relative;
        color: #000;
        padding: 0 0 0 16px;
        color: #000;
        vertical-align: middle;
        font-size: 25px; }
        .case-slider .case-slide-container .swiper-button-next .arrow::before, .case-slider .case-slide-container .swiper-button-next .arrow::after {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          vertical-align: middle; }
        .case-slider .case-slide-container .swiper-button-next .arrow::before {
          left: 0;
          width: 15px;
          height: 1px;
          background: #fff; }
        .case-slider .case-slide-container .swiper-button-next .arrow::after {
          left: 5px;
          width: 8px;
          height: 8px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
    .case-slider .case-slide-container .case-slide-nums {
      position: absolute;
      left: calc( 100% - calc( 50vw / 14.4 + 90px ));
      right: auto;
      bottom: calc(12.5vw / 14.4 + 27.5px);
      z-index: 200;
      white-space: nowrap; }
      @media screen and (max-width: 600px) {
        .case-slider .case-slide-container .case-slide-nums {
          width: calc(200vw / 14.4); } }
      .case-slider .case-slide-container .case-slide-nums .case-slide-num {
        font-size: calc(9vw / 14.4 + 15px);
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300; }
        .case-slider .case-slide-container .case-slide-nums .case-slide-num .num {
          display: none;
          font-size: calc(46vw / 14.4 + 20px); }
          .case-slider .case-slide-container .case-slide-nums .case-slide-num .num.active {
            display: inline-block; }
    .case-slider .case-slide-container .swiper-pagination {
      display: none;
      text-align: right;
      padding-right: 7.5%;
      transform: translateY(360%); }
      .case-slider .case-slide-container .swiper-pagination .swiper-pagination-bullet {
        display: inline-block;
        width: 13px;
        height: 13px;
        line-height: 13px;
        border-radius: 50%;
        margin: 0 2px; }
        .case-slider .case-slide-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background: #22a7c3; }
      @media screen and (max-width: 600px) {
        .case-slider .case-slide-container .swiper-pagination {
          display: block; } }

.case-desc-container {
  background-color: #f8f8f8; }

.case-desc-area {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  padding-top: calc( 12.5vw / 14.4 + 27.5px);
  padding-bottom: 70px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  display: none; }
  .case-desc-area.active {
    display: block; }
  .case-desc-area.culture {
    border-bottom: none; }
  @media screen and (max-width: 600px) {
    .case-desc-area {
      width: 90%; } }
  .case-desc-area .case-desc-top {
    padding-top: 15px; }
    @media screen and (max-width: 600px) {
      .case-desc-area .case-desc-top {
        padding-top: 30px; } }
    .case-desc-area .case-desc-top .case-name {
      color: #22a7c3;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: .04em;
      line-height: 1.4;
      transition: all .8s;
      margin-bottom: 10px; }
      .case-desc-area .case-desc-top .case-name.culture {
        font-size: 1.8rem;
        margin-bottom: 25px; }

.srvs-links {
  background-color: #f8f8f8; }
  .srvs-links__inner {
    width: 90%;
    margin: auto;
    padding: 17px 0 60px 0;
    display: flex; }
    @media screen and (max-width: 768px) {
      .srvs-links__inner {
        width: 86.666%; } }
    @media screen and (max-width: 960px) {
      .srvs-links__inner {
        flex-direction: column;
        padding: 12px 0 calc(40vw / 7.5) 0; } }
    .srvs-links__inner .srv-part {
      position: relative;
      width: 50%; }
      @media screen and (max-width: 960px) {
        .srvs-links__inner .srv-part {
          width: 100%; } }
      @media screen and (max-width: 960px) {
        .srvs-links__inner .srv-part:nth-child(1) {
          margin-bottom: calc(40vw /7.5); } }
      .srvs-links__inner .srv-part .service-img-box {
        position: relative;
        height: 220px; }
        @media screen and (max-width: 768px) {
          .srvs-links__inner .srv-part .service-img-box {
            height: calc(220vw /7.5); } }
        .srvs-links__inner .srv-part .service-img-box img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .srvs-links__inner .srv-part .service-img-box::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(14, 76, 89, 0.3); }
      .srvs-links__inner .srv-part .service-name {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 500;
        font-size: 2.2rem;
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: center; }
        @media screen and (max-width: 960px) {
          .srvs-links__inner .srv-part .service-name {
            font-size: calc( 25vw / 7.5); } }
        @media screen and (max-width: 600px) {
          .srvs-links__inner .srv-part .service-name {
            font-size: calc( 28vw / 7.5); } }
        .srvs-links__inner .srv-part .service-name span {
          display: block;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 1.2rem;
          color: #22a7c3;
          margin-top: 15px;
          letter-spacing: .03em; }

@media screen and (max-width: 600px) {
  .hide-sp {
    display: none !important; } }

.hide-pc {
  display: none !important; }
  @media screen and (max-width: 600px) {
    .hide-pc {
      display: block !important; } }

span.hide-pc {
  display: none !important; }
  @media screen and (max-width: 600px) {
    span.hide-pc {
      display: inline !important; } }

.show-sp {
  display: none !important; }
  @media screen and (max-width: 600px) {
    .show-sp {
      display: block !important; } }

.only-pc {
  display: block !important; }
  @media screen and (max-width: 1180px) {
    .only-pc {
      display: none !important; } }

.hide-tabMid {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .hide-tabMid {
      display: none !important; } }

.show-tabMid {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .show-tabMid {
      display: block !important; } }

.hide-tabMid {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .hide-tabMid {
      display: none !important; } }

.show-tabMid {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .show-tabMid {
      display: block !important; } }

.show-tab-long {
  display: none !important; }
  @media screen and (min-height: 900px) and (orientation: portrait) {
    .show-tab-long {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .show-tab-long {
      display: block !important; } }

.hide-tab-long {
  display: block !important; }
  @media screen and (min-height: 900px) and (orientation: portrait) {
    .hide-tab-long {
      display: none !important; } }
  @media screen and (max-width: 768px) {
    .hide-tab-long {
      display: none !important; } }

.hide-spVer {
  display: block; }
  @media screen and (max-width: 480px) {
    .hide-spVer {
      display: none; } }

.show-spVer {
  display: none; }
  @media screen and (max-width: 480px) {
    .show-spVer {
      display: block; } }

.section-contact__inner {
  width: 90%;
  max-width: 1080px;
  margin: auto;
  margin: auto; }
  @media screen and (max-width: 600px) {
    .section-contact__inner {
      margin-top: 15px; } }

.contact-msg {
  margin-bottom: 30px; }
  .contact-msg-txt {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    color: #6c6c6c; }
    .contact-msg-txt .red {
      color: #ff3b3b; }

.wpcf7 .wpcf7-form-control-wrap.menu-730::before,
.wpcf7 .wpcf7-form-control-wrap.career-pref::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 40px;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  z-index: 1; }
  @media screen and (max-width: 600px) {
    .wpcf7 .wpcf7-form-control-wrap.menu-730::before,
    .wpcf7 .wpcf7-form-control-wrap.career-pref::before {
      right: 20px; } }

.wpcf7 select.wpcf7-select {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  max-width: 753px;
  height: 51px;
  border-radius: 0;
  background-color: #22a7c3;
  border: none;
  padding: 0 15px;
  cursor: pointer;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: .1em;
  line-height: 1.8;
  color: #fff;
  position: relative; }
  @media screen and (max-width: 480px) {
    .wpcf7 select.wpcf7-select {
      font-size: 1.2rem; } }

.wpcf7 .contact-body {
  margin-top: 20px;
  padding-bottom: 60px;
  border-bottom: 1px solid #6c6c6c; }
  @media screen and (max-width: 600px) {
    .wpcf7 .contact-body {
      padding-bottom: 25px; } }
  .wpcf7 .contact-body .contact-inner {
    width: 100%;
    max-width: 753px; }
  .wpcf7 .contact-body .row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 753px;
    margin-bottom: 20px; }
    @media screen and (max-width: 600px) {
      .wpcf7 .contact-body .row {
        flex-direction: column;
        margin-bottom: 10px; } }
  .wpcf7 .contact-body .th {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    color: #000;
    text-align: left;
    width: 26%;
    padding-top: 15px; }
    @media screen and (max-width: 600px) {
      .wpcf7 .contact-body .th {
        width: 100%;
        padding: 8px 0; } }
    .wpcf7 .contact-body .th span {
      color: #ff0000; }
  .wpcf7 .contact-body .td {
    width: 74%; }
    @media screen and (max-width: 600px) {
      .wpcf7 .contact-body .td {
        width: 100%; } }
    .wpcf7 .contact-body .td input {
      width: 100%;
      height: 51px;
      font-family: 'Noto Sans JP', sans-serif;
      font-weight: 400;
      font-size: 1.4rem;
      letter-spacing: .1em;
      line-height: 1.8;
      color: #000;
      padding: 0 24px;
      border: 1px solid #6c6c6c; }
      .wpcf7 .contact-body .td input::placeholder {
        color: #c6c6c6; }
    .wpcf7 .contact-body .td textarea {
      width: 100%;
      border: 1px solid #6c6c6c; }

.wpcf7 .contact-supple .privacy {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 753px;
  padding: 60px 0; }
  @media screen and (max-width: 960px) {
    .wpcf7 .contact-supple .privacy {
      flex-direction: column; } }
  @media screen and (max-width: 600px) {
    .wpcf7 .contact-supple .privacy {
      padding: 25px 0; } }
  .wpcf7 .contact-supple .privacy .privacy-title {
    width: 26%;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    color: #000;
    letter-spacing: 0; }
    @media screen and (max-width: 960px) {
      .wpcf7 .contact-supple .privacy .privacy-title {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .wpcf7 .contact-supple .privacy .privacy-title {
        padding-bottom: 8px; } }
  .wpcf7 .contact-supple .privacy .privacy-content {
    width: 74%;
    white-space: nowrap;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.8;
    color: #6c6c6c;
    letter-spacing: .02em; }
    @media screen and (max-width: 1180px) {
      .wpcf7 .contact-supple .privacy .privacy-content {
        white-space: initial; } }
    @media screen and (max-width: 960px) {
      .wpcf7 .contact-supple .privacy .privacy-content {
        width: 100%; } }

.wpcf7 .contact-supple .privacy-condition {
  width: 100%;
  height: 285px;
  color: #6c6c6c;
  border: none;
  background-color: #f8f8f8;
  overflow-y: scroll;
  padding: 34px; }

.wpcf7 .btn-next {
  padding: 60px 0; }
  @media screen and (max-width: 600px) {
    .wpcf7 .btn-next {
      padding: 25px 0; } }
  .wpcf7 .btn-next .btn-box {
    margin: auto; }
    .wpcf7 .btn-next .btn-box .btn-box-inner::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      right: 10.8249%;
      background-image: url(../../../img/common/arr.png);
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1; }
    .wpcf7 .btn-next .btn-box .btn-box-inner .btn-more::after {
      display: none; }
    .wpcf7 .btn-next .btn-box .btn-box-inner input {
      cursor: pointer; }

.wpcf7-not-valid-tip {
  margin-top: 15px;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: .1em;
  line-height: 1.8;
  color: #000;
  color: #ff0000;
  font-size: 1.2rem !important; }

.common-body {
  padding-top: 30px;
  height: 100vh; }

.common-body-main {
  text-align: center;
  padding-bottom: 52px; }
  .common-body-main .common-img-box {
    width: 80%;
    max-width: 365px;
    margin: 0 auto 75px; }
    @media screen and (max-width: 600px) {
      .common-body-main .common-img-box {
        margin: 0 auto 35px; } }
    .common-body-main .common-img-box img {
      width: 100%; }
  .common-body-main .common-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 7.1rem;
    letter-spacing: .03em;
    color: #1e2b69;
    margin-bottom: 75px; }
    @media screen and (max-width: 600px) {
      .common-body-main .common-title {
        font-size: 4.1rem;
        margin-bottom: 35px; } }
  .common-body-main .common-txt {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: .06em;
    line-height: 1.8;
    color: #6c6c6c;
    padding: 0 15px; }

.btn-box-inner .btn-more::after {
  background-image: url(../../../assets/img/common/arr.png); }
